@import "~bootstrap/scss/bootstrap";
@import "~normalize.css/normalize";
@import "~react-day-picker/lib/style";
@import "~tippy.js/dist/tippy.css";
@import "~tippy.js/themes/light.css";
@import "~tippy.js/themes/translucent.css";
@import "~tippy.js/animations/shift-away.css";
@import "~react-sliding-pane/dist/react-sliding-pane.css";
@import "~react-toastify/dist/ReactToastify.css";

@import "utilities/variables";
@import "utilities/mixins";
@import "utilities/functions";
@import "utilities/animations";
@import "utilities/sizes";
@import "utilities/spaces";
@import "utilities/flex";
@import "utilities/text";
@import "utilities/colors";
@import "utilities/print";

@import "base/reset";
@import "base/typography";

@import "layout/error-section";
@import "layout/footer";
@import "layout/form";
@import "layout/header";
@import "layout/page";
@import "layout/table";
@import "layout/modal";

@import "components/accordion";
@import "components/alert";
@import "components/breadcrumbs";
@import "components/button";
@import "components/day-picker";
@import "components/file-input";
@import "components/list";
@import "components/pagination";
@import "components/pill";
@import "components/slide-pane";
@import "components/spinner";
@import "components/step-indicators";
@import "components/steppers";
@import "components/tag";
@import "components/timeline";
@import "components/workflow-status";
@import "components/contents";
@import "components/tabs";
@import "components/button-dropdown";
@import "components/toast";

@import "pages/account-setup";
@import "pages/add-employee";
@import "pages/login";
@import "pages/stp-details";
@import "pages/stp-new";
@import "pages/stp-summary";

